import React from "react"

import PicturesDesktop from "../components/pictures/PicturesDesktop"

const Pictures = () => (
  <div id="arnaud-bresson-pictures">
    <PicturesDesktop />
  </div>
)

export default Pictures
