import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PictureDesktop = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1600, quality: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const imageFluid = image.node.childImageSharp.fluid
      return (
        <Img
          className="picture"
          alt={props.alt}
          fluid={imageFluid}
          imgStyle={{ objectFit: "contain" }}
          style={{ maxHeight: "82vh" }}
        />
      )
    }}
  />
)

export default PictureDesktop
